<template>
  <div>
    <h1>Модерация по замене ника</h1>
    <div v-if="items" class="d-flex flex-wrap justify-content-between align-items-center">
      <div
        :key="item.id"
        v-for="item in items"
        style="min-width: 350px"
        class="hovering__pad my-4 p-4"
      >
        <div class="d-flex justify-content-between">
          <div>
            <p>Старый ник</p>
            <p class="gray-text fs-18 mb-4">{{ item.old_instaname }}</p>
          </div>
          <div style="color: #b4bdc1" class="text-right">
            {{ new Date() | moment('DD.MM.YY HH:mm') }}<br />
            №{{ item.id }}
          </div>
        </div>
        <p>Новый ник</p>
        <p class="gray-text fs-18 mb-4">{{ item.new_instaname }}</p>
        <p>Ссылка на аккаунт</p>
        <b-button
          variant="link"
          class="fs-16 mb-4"
          :href="`https://instagram.com/${item.new_instaname}`"
          >{{ `https://instagram.com/${item.new_instaname}` }}</b-button
        >
        <div style="cursor: pointer" class="d-flex justify-content-center align-items-center">
          <div
            @click="() => answerRequest({ ...item, status: 2 })"
            style="min-width: 60px; height: 40px; border: 1px solid #d5dfe4"
            class="w-100 clickNo d-flex justify-content-center align-items-center"
          >
            <b-icon style="width: 30px; height: 30px" icon="x" variant="danger" />
          </div>
          <div
            @click="() => answerRequest({ ...item, status: 3 })"
            style="min-width: 60px; height: 40px; border: 1px solid #d5dfe4"
            class="w-100 d-flex justify-content-center align-items-center"
          >
            <b-icon style="width: 25px; height: 25px" icon="heart-fill" variant="success" />
          </div>
          <div
            @click="() => answerRequest({ ...item, status: 2 })"
            style="min-width: 60px; height: 40px; border: 1px solid #d5dfe4"
            class="w-100 clickOther d-flex justify-content-center align-items-center"
          >
            <div class="text">Другое</div>
          </div>
        </div>
      </div>
    </div>
    <loader v-else></loader>
  </div>
</template>

<script>
import bloggers from '@main/api/bloggers';
import Loader from '../Loader.vue';

export default {
  components: { Loader },
  data: () => ({
    items: null,
  }),
  methods: {
    answerRequest(data) {
      bloggers.changeInstanameRequest.moderation.update(data);
      this.updateData();
    },
    updateData() {
      bloggers.changeInstanameRequest.moderation.list({ status: 1 }).then((res) => {
        this.items = res.results;
      });
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>

<style lang="scss" scoped></style>
